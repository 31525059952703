import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "spacing__style" }
const _hoisted_2 = { class: "spacing__style__tab__item" }
const _hoisted_3 = { class: "spacing__style__outer__rect" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSliderIcon = _resolveComponent("BaseSliderIcon")!
  const _component_PanelStyle = _resolveComponent("PanelStyle")!

  return (_openBlock(), _createBlock(_component_PanelStyle, { title: `Padding` }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paddingOptions, (option, key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: key,
                onClick: ($event: any) => (_ctx.changePaddingOption(option)),
                class: _normalizeClass([[option, { active: _ctx.activePadding === option }], "spacing__style__inner__rect"])
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.padding[option]), 1)
              ], 10, _hoisted_4))
            }), 128)),
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.applyPaddingToAll && _ctx.applyPaddingToAll(...args))),
              class: _normalizeClass([{ active: _ctx.activePadding === 'center' }, "spacing__style__inner__rect center"])
            }, [
              (_ctx.centerValue)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.centerValue), 1))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _createVNode(_component_BaseSliderIcon, {
            modelValue: _ctx.singlePadding,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.singlePadding) = $event)),
            icon: "canvas/panel/styles/spacing"
          }, null, 8, ["modelValue"])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "spacing__style__description" }, " This spacing is applied to all sides of the inner component element ", -1))
      ])
    ]),
    _: 1
  }))
}